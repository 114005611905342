.scroll {
    overflow-y: auto;
    width: 100%;
    padding-right: 1rem;
}

.scroll::-webkit-scrollbar {
    width: 9px !important;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #414141 !important;
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #818181 !important;
}


::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #101010;

}

::-webkit-scrollbar-thumb {
    background: #ffffff40;
    border-radius: 4px;

}

.css-1dimb5e-singleValue {
    color: #4BFFE0 !important;
}