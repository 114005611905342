@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Outfit-Light";
  src: url("./assets/fonts/Outfit/static/Outfit-Light.ttf");
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("./assets/fonts/Outfit/static/Outfit-Regular.ttf");
}

@font-face {
  font-family: "Outfit-SemiBold";
  src: url("./assets/fonts/Outfit/static/Outfit-SemiBold.ttf");
}

@font-face {
  font-family: "Outfit-Medium";
  src: url("./assets/fonts/Outfit/static/Outfit-Medium.ttf");
}

@font-face {
  font-family: "Outfit-Bold";
  src: url("./assets/fonts/Outfit/static/Outfit-Bold.ttf");
}

@font-face {
  font-family: "Made-Soulmaze-Regular";
  src: url("./assets/fonts/made_soulmaze/MADE Soulmaze Brush PERSONAL USE.otf");
}
